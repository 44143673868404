import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'

export default function GoButton({ audioLoaded, imagesLoaded, handleClick }) {
	// constructor(props) {
	// 	super(props)
	// 	this.state = {
	// 		imagesLoaded: this.props.imagesLoaded,
	// 		audioLoaded: this.props.audioLoaded,
	// 		isPortrait: true,
	// 		data: this.props.data,
	// 	}
	// }
	let button
	let [portrait, setPortrait] = useState(false)

	const checkDeviceOrientation = () => {
		if (window.innerHeight > window.innerWidth) {
			// this.setState({ isPortrait: true })
			setPortrait(true)
		} else {
			// this.setState({ isPortrait: false })
			setPortrait(false)
		}
	}

	useEffect(() => {
		checkDeviceOrientation()
		window.addEventListener('resize', checkDeviceOrientation)
	})

	if (!audioLoaded) {
		button = (
			<button className="button button--loading" disabled>
				Loading assets&hellip;
				{/*{this.state.data}*/}
			</button>
		)
	} else if (portrait) {
		button = (
			<button className="button" disabled>
				Your device must be in landscape mode
			</button>
		)
	} else {
		button = (
			<button className="button" onClick={handleClick}>
				I accept these terms, let's go!
			</button>
		)
	}

	return button
}
