import Image from 'next/image'

export const Slide = (props) => (
	<Image {...props} width={560} height={315} placeholder="blur" alt="" />
)

export const SquareSlide = (props) => (
	<Image {...props} width={280} height={280} placeholder="blur" alt="" />
)

export const DotButton = ({ selected, onClick }) => (
	<button className={selected ? 'active' : ''} onClick={onClick} />
)
