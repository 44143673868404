import { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import ClassNames from 'embla-carousel-class-names'
import { DotButton } from './carouselComponents'

const EmblaCarousel = ({ children, variant, slides }) => {
	const [viewportRef, embla] = useEmblaCarousel(
		{
			skipSnaps: true,
			loop: true,
			slidesToScroll: 1,
			align: 'start',
		},
		[ClassNames()]
	)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [scrollSnaps, setScrollSnaps] = useState([])

	const scrollTo = useCallback(
		(index) => embla && embla.scrollTo(index),
		[embla]
	)

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
	}, [embla, setSelectedIndex])

	useEffect(() => {
		if (!embla) return
		onSelect()
		setScrollSnaps(embla.scrollSnapList())
		embla.on('select', onSelect)
	}, [embla, setScrollSnaps, onSelect])

	return (
		<div className={`carousel${slides === 2 ? ' carousel--dual' : ''}`}>
			<div className="carousel__viewport" ref={viewportRef}>
				<div className="carousel__tray">{children}</div>
			</div>
			<div className="carousel__dots">
				{scrollSnaps.map((_, index) => (
					<DotButton
						key={index}
						selected={index === selectedIndex}
						onClick={() => scrollTo(index)}
					/>
				))}
			</div>
		</div>
	)
}

export default EmblaCarousel
