import { theTitle } from './layout'
import Image from 'next/image'
import ExternalIcon from '../public/icons/external-link.svg'

export function SplashContent() {
	return (
		<>
			<h1>{theTitle}</h1>
			<h2>Exhibit #1 – The Map of Mum</h2>
			<p>
				Please make sure your sound is on before entering. For the best
				experience we recommend avoiding small mobile devices. If using
				a mobile device it must be in landscape mode.
			</p>
			<p>
				We use Google Analytics to find out some basic things about your
				device. By clicking the button below you agree to a cookie being
				stored on your internal storage.
			</p>
		</>
	)
}

export function TheMapOfMumContent() {
	return (
		<>
			<h2>The Map of Mum</h2>
			<p>
				In early 2019 my mum started showing some signs of unusual
				memory loss.
			</p>

			<p>
				I quietly started to investigate what she could remember and
				found that talking about music was the key to unlocking her
				strongest memories.
			</p>

			<p>
				Through a year of tea, biscuits and conversation I discovered
				that music is one of the things that glues us together and that
				listening, playing and writing together could give me a new and
				profound understanding about who she is and how our relationship
				works.
			</p>

			<p>
				I set out to see if I could describe her by reinventing the
				music she loves, and the musical memories we share.
			</p>

			<p>
				The Map of Mum is the first exhibit in the Museum of Musical
				Memory.
			</p>
		</>
	)
}

export function AfterTheStormContent() {
	return (
		<>
			<h2>After the Storm</h2>
			<p>
				A long shadow predisposes footfall
				<br />
				darkens the rise and hollows ahead
			</p>
			<p>
				Behind that solid form of
				<br />
				apparent being
				<br />
				the sun discharges the clouds
				<br />
				pierces their rain pillows
				<br />
				to enhance the cobalt sea
			</p>
			<p>
				Beyond the tipped white waves
				<br />
				to which the shadow sets
				<br />a sky both calm and grey-wet
				<br />
				dissects horizon
				<br />
				and sun-bright shards of chalk
				<br />
				stand guard
				<br />
				alone
				<br />
				by yet rain-cloaked cliff
			</p>
			<p>
				A distant wedge
				<br />
				of sky-blue calm
				<br />
				belies the storm just passed
				<br />
				Now the kiss of whisper-warm wind
				<br />
				skitters the fallen leaves
				<br />
				so recently dissembled by
				<br />
				savage blast
				<br />
				blows a golden rainbow
				<br />
				tumbling over scarp-edge
			</p>
			<p>
				From green-grass down
				<br />
				by shivering sea
				<br />
				my shadow in symphysis
				<br />
				awed
				<br />
				drinks the in-breath splendour
			</p>
			<p>
				and wonders
				<br />
				at such fleeting
				<br />
				beauty
			</p>
			<p>
				<strong>Sue Williams, 2003</strong>
			</p>
		</>
	)
}

export function CreditsContent() {
	return (
		<>
			<h2>Credits</h2>

			<p>
				Music written, recorded, and produced by{' '}
				<a
					href="https://www.kathbuckler.com/"
					target="_blank"
					rel="noreferrer"
				>
					Kath Buckler
					<ExternalIcon />
				</a>{' '}
				With thanks to Alfie Gidley for capturing the Dawn Chorus.
			</p>

			<p>
				Artwork by{' '}
				<a
					href="https://stevenbuckler.co.uk/"
					target="_blank"
					rel="noreferrer"
				>
					Steven Buckler
					<ExternalIcon />
				</a>
			</p>

			<p>
				Website by{' '}
				<a href="https://mghd.dev/" target="_blank" rel="noreferrer">
					MGHD
					<ExternalIcon />
				</a>
			</p>

			<p>
				Created with support from Arts Council England's{' '}
				<a
					href="https://www.artscouncil.org.uk/DYCP"
					target="_blank"
					rel="noreferrer"
				>
					Developing your Creative Practice
					<ExternalIcon />
				</a>{' '}
				scheme.
			</p>

			<div className="margin-bottom">
				<Image
					src={'/images/grant_jpeg_black.jpg'}
					width={200}
					height={63}
					alt=""
				/>
			</div>

			<p>&copy; Kath Buckler {new Date().getFullYear()}</p>
		</>
	)
}
