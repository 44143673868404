import { useState } from 'react'
import Head from 'next/head'
import Image from 'next/image'
import Header from './header'
import Set from './set'
import ReactAudioPlayer from 'react-audio-player'
import EmblaCarousel from './carousel'
import { Slide, SquareSlide } from './carouselComponents'
import GoButton from './goButton'
import PauseButton from './pauseButton'
import AudioIcon from '../public/icons/audio.svg'
import ImageIcon from '../public/icons/aperture.svg'
import TextIcon from '../public/icons/text.svg'
import ReactGA from 'react-ga4'
import {
	SplashContent,
	TheMapOfMumContent,
	AfterTheStormContent,
	CreditsContent,
} from './content'

import Landscape1 from '../public/images/Mum/Childhood-Hotel-Home.jpg'
import Landscape2 from '../public/images/Mum/Garden.jpg'
import Landscape3 from '../public/images/Mum/Mum-with-her-Dad.jpg'
import Landscape4 from '../public/images/Mum/Mums-Graduation.jpg'
import Landscape5 from '../public/images/Mum/Mylor-2021.jpg'
import Landscape6 from '../public/images/Mum/Piano-Hands.jpg'
import Landscape7 from '../public/images/Mum/Somerset-1990.jpg'
import Landscape8 from '../public/images/Mum/Teacher-Training-1969.jpg'

import Square1 from '../public/images/Album-covers/Billie-Holiday.jpg'
import Square2 from '../public/images/Album-covers/Britten.jpg'
import Square3 from '../public/images/Album-covers/DUKE-TRANE.jpg'
import Square4 from '../public/images/Album-covers/Eno.jpg'
import Square5 from '../public/images/Album-covers/Honey-Rue.jpg'
import Square6 from '../public/images/Album-covers/Le-Mystere-Des-Voix-Bulgares.jpg'
import Square7 from '../public/images/Album-covers/Peter-Wolf.jpg'
import Square8 from '../public/images/Album-covers/R-J.jpg'
import Square9 from '../public/images/Album-covers/Rautavaara.jpg'
import Square10 from '../public/images/Album-covers/Ravel.jpg'

const landscapeImages = [
	Landscape1,
	Landscape2,
	Landscape3,
	Landscape4,
	Landscape5,
	Landscape6,
	Landscape7,
	Landscape8,
]

const squareImages = [
	Square1,
	Square2,
	Square3,
	Square4,
	Square5,
	Square6,
	Square7,
	Square8,
	Square9,
	Square10,
]

export const productionDomain = process.env.NEXT_PUBLIC_SITE_URL
export const theTitle = 'The Museum Of Musical Memory'
export const animationSeconds = 30

// export default class Layout extends React.Component {
// 	constructor(props) {
// 		super(props)
// 		this.state = {
// 			imagesLoaded: false,
// 			audioLoaded: false,
// 			faceAnimating: false,
// 			faceAnimated: false,
// 			running: false,
// 			data: 'Data from parent',
// 		}
// 	}
export default function Layout() {
	let [audioLoaded, setAudioLoaded] = useState(false)
	let [imagesLoaded, setImagesLoaded] = useState(false)
	let [faceAnimating, setFaceAnimating] = useState(false)
	let [faceAnimated, setFaceAnimated] = useState(false)
	let [running, setRunning] = useState(false)
	let [paused, setPaused] = useState(false)
	let [fin, setFin] = useState(false)

	const handleCanPlayThrough = () => {
		const mainAudio = document.getElementById('audio--main')
		mainAudio.pause()
		// this.setState({ audioLoaded: true })
		setAudioLoaded(true)

		mainAudio.addEventListener('ended', function () {
			setFin(true)
		})
	}

	const handleLoadingComplete = () => {
		// this.setState({ imagesLoaded: true })
		setImagesLoaded(true)
	}

	const handleClick = () => {
		// this.setState({ running: true })
		setRunning(true)
		const face = document.querySelector('.face')
		const mainAudio = document.getElementById('audio--main')

		mainAudio.play()

		setTimeout(() => {
			face.classList.add('animating')
			setFaceAnimating(true)
			console.log('%cfaceAnimating', 'color:blue;')
		}, (animationSeconds / 2) * 1000)

		setTimeout(() => {
			face.classList.add('animated')
			setFaceAnimated(true)
			console.log('%cfaceAnimated', 'color:green;')
		}, animationSeconds * 1000)

		// Load Analytics if not development
		if (window.location.href.indexOf(productionDomain) > -1) {
			ReactGA.initialize('G-728EHWLJG2')
			ReactGA.send('pageview')
		}
	}

	const handlePause = () => {
		const mainAudio = document.getElementById('audio--main')

		if (fin) {
			mainAudio.currentTime = 0
			setFin(false)
			setPaused(true)
		} else if (mainAudio.paused) {
			mainAudio.play()
			setPaused(false)
		} else {
			mainAudio.pause()
			setPaused(true)
		}
	}

	return (
		<>
			<Head>
				<title>{theTitle}</title>
				<meta
					name="description"
					content={`${theTitle} is an online collection of human exhibits, their stories told through music.`}
				/>
				<meta name="author" content="MGHD" />
				<meta property="og:title" content={theTitle} />
				<meta
					property="og:image"
					content={`${productionDomain}/images/MoM-Sketch.jpg`}
				/>
				<meta property="og:image:width" content="1080" />
				<meta property="og:image:height" content="1080" />
				<meta name="twitter:card" content="summary_large_image" />
			</Head>

			<main>
				{running ? (
					<div className="alert">
						<p>
							The orientation of your device is{' '}
							<strong>portrait</strong>. Please swap to{' '}
							<strong>landscape</strong> by rotating it 90
							degrees.
						</p>
					</div>
				) : null}
				<header className={running ? 'splash accepted' : 'splash'}>
					<div className="splash__content">
						<SplashContent />
						<GoButton
							imagesLoaded={imagesLoaded}
							audioLoaded={audioLoaded}
							// data={this.state.data}
							handleClick={handleClick}
						/>
					</div>
				</header>

				<Image
					priority
					className="blur"
					src="/images/MoM_hiResBG.png"
					width={1920}
					height={1080}
					quality={1}
					sizes="100vw"
					alt=""
				/>

				<div className="face">
					<Image
						priority
						src="/images/MoM_hiResBG.png"
						width={1920}
						height={1080}
						sizes="100vw"
						alt=""
						onLoad={handleLoadingComplete}
					/>

					<div className="face__part">
						<Image
							priority
							src="/images/MoM_hiResSpoke02.png"
							width={1920}
							height={1080}
							sizes="100vw"
							alt=""
						/>
					</div>

					<div className="face__part">
						<Image
							priority
							src="/images/MoM_hiResSpoke01.png"
							width={1920}
							height={1080}
							sizes="100vw"
							alt=""
						/>
					</div>

					{imagesLoaded ? (
						<ReactAudioPlayer
							src="/audio/The-Map-Of-Mum-Complete-Mix.mp3"
							className="face__audio"
							id="audio--main"
							autoPlay
							preload="auto"
							onCanPlayThrough={handleCanPlayThrough}
							controlsList="nodownload"
						/>
					) : null}

					<PauseButton
						paused={paused}
						fin={fin}
						handlePause={handlePause}
					/>

					{faceAnimated ? (
						<div>
							<Set showTime={31}>
								<TheMapOfMumContent />
							</Set>

							<Set icon={<AudioIcon />} showTime={103}>
								<h2>Floating Pink Bottle</h2>
								<ReactAudioPlayer
									src="/audio/Floating-Pink-Bottle.mp3"
									preload="auto"
									controls
									controlsList="nodownload"
								/>
							</Set>

							<Set icon={<ImageIcon />} showTime={353}>
								<h2>Photos from 1960 - 2021</h2>
								<EmblaCarousel slides={1}>
									{landscapeImages.map((image, key) => (
										<Slide key={key} src={image} />
									))}
								</EmblaCarousel>
							</Set>

							<Set icon={<TextIcon />} showTime={511}>
								<AfterTheStormContent />
							</Set>

							<Set icon={<AudioIcon />} showTime={653}>
								<h2>Piano Origins</h2>
								<ReactAudioPlayer
									src="/audio/Piano-Origins-2.mp3"
									preload="auto"
									controls
									controlsList="nodownload"
								/>
							</Set>

							<Set icon={<ImageIcon />} showTime={855}>
								<h2>Music we listened to</h2>
								<EmblaCarousel slides={2}>
									{squareImages.map((image, key) => (
										<SquareSlide key={key} src={image} />
									))}
								</EmblaCarousel>
							</Set>

							<Set showTime={879}>
								<CreditsContent />
							</Set>
						</div>
					) : null}
				</div>
			</main>
		</>
	)
}
