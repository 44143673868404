import InfoIcon from '../public/icons/info.svg'
import ExternalIcon from '../public/icons/external-link.svg'

import { useState } from 'react'

export default function Header({ title, subtitle }) {
	let [isActive, setActive] = useState(false)

	return (
		<>
			<button
				className="burger"
				onClick={() => setActive(!isActive)}
				aria-label="Toggle information"
			>
				<InfoIcon />
			</button>
			<header className={isActive ? 'flyout active' : 'flyout'}>
				<div className="flyout__content">
					<h1>{title}</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Orci a scelerisque purus semper eget duis.
						Dui ut ornare lectus sit amet est placerat. Aliquam
						ultrices sagittis orci a scelerisque purus semper eget
						duis. Fringilla urna porttitor rhoncus dolor purus non
						enim praesent elementum. Fermentum iaculis eu non diam
						phasellus vestibulum. A arcu cursus vitae congue mauris
						rhoncus. Elit scelerisque mauris pellentesque pulvinar.
						Quis hendrerit dolor magna eget est lorem ipsum dolor
						sit. Sed elementum tempus egestas sed sed risus pretium.
						Diam quam nulla porttitor massa id. Nunc aliquet
						bibendum enim facilisis gravida neque convallis a cras.
						Pharetra massa massa ultricies mi. Diam ut venenatis
						tellus in metus vulputate eu.
					</p>

					<p>
						Morbi tristique senectus et netus et malesuada fames ac.
						Amet consectetur adipiscing elit pellentesque. Convallis
						posuere morbi leo urna molestie at elementum eu
						facilisis. Eget felis eget nunc lobortis mattis. Et
						molestie ac feugiat sed lectus vestibulum mattis
						ullamcorper velit. Pulvinar neque laoreet suspendisse
						interdum. At ultrices mi tempus imperdiet nulla
						malesuada pellentesque. Volutpat commodo sed egestas
						egestas fringilla phasellus faucibus. Nec sagittis
						aliquam malesuada bibendum arcu vitae elementum. Risus
						commodo viverra maecenas accumsan lacus vel facilisis.
						Odio euismod lacinia at quis risus sed vulputate. Auctor
						neque vitae tempus quam pellentesque nec nam. Erat
						imperdiet sed euismod nisi porta lorem mollis.
					</p>

					<p>
						In tellus integer feugiat scelerisque varius morbi.
						Lectus urna duis convallis convallis. Id ornare arcu
						odio ut sem nulla pharetra diam sit. Blandit volutpat
						maecenas volutpat blandit. Et ligula ullamcorper
						malesuada proin libero nunc consequat. Porttitor massa
						id neque aliquam vestibulum morbi blandit cursus.
						Pellentesque sit amet porttitor eget. Imperdiet nulla
						malesuada pellentesque elit eget. Non sodales neque
						sodales ut etiam sit amet nisl purus. Sit amet
						consectetur adipiscing elit pellentesque. Sem fringilla
						ut morbi tincidunt augue interdum velit euismod. Ac ut
						consequat semper viverra. Dignissim diam quis enim
						lobortis scelerisque fermentum dui faucibus in. Iaculis
						urna id volutpat lacus laoreet non curabitur gravida.
						Vitae suscipit tellus mauris a diam maecenas sed enim
						ut.
					</p>
				</div>
				<p className="flyout__credit">
					Website by{' '}
					<a href="https://mghd.dev/" rel="noopener" target="_blank">
						MGHD
						<ExternalIcon />
					</a>
				</p>
			</header>
		</>
	)
}
