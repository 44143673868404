import PauseIcon from '../public/icons/pause.svg'
import PlayIcon from '../public/icons/play.svg'
import RewindIcon from '../public/icons/rewind.svg'

export default function PauseButton({ paused, fin, handlePause }) {
	let icon

	if (fin) {
		icon = <RewindIcon />
	} else if (paused) {
		icon = <PlayIcon />
	} else {
		icon = <PauseIcon />
	}

	return (
		<button className="face__pause" onClick={handlePause}>
			{icon}
		</button>
	)
}
