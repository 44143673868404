import { useState } from 'react'
import CloseIcon from '../public/icons/close.svg'
import InfoIcon from '../public/icons/info.svg'
import { animationSeconds } from './layout'

export default function Set({ children, icon, showTime }) {
	let [modalActive, setModalActive] = useState(false)
	let [visible, setVisible] = useState(false)
	let [viewed, setViewed] = useState(false)
	const mainAudio = document.getElementById('audio--main')

	// setTimeout(() => {
	// setVisible(true)
	// }, (showTime - animationSeconds) * 1000)

	setInterval(() => {
		// console.log('tick')
		if (Math.ceil(mainAudio.currentTime) > showTime - 1) {
			setVisible(true)
		} else {
			setVisible(false)
		}
	}, 1000)

	// mainAudio.addEventListener('timeupdate', function () {
	// 	// console.log('tick')
	// 	if (Math.ceil(mainAudio.currentTime) > showTime) {
	// 		setVisible(true)
	// 	} else {
	// 		setVisible(false)
	// 	}
	// })

	const handleClick = () => {
		setModalActive(true)
		setViewed(true)
	}

	return visible ? (
		<>
			<button
				className={viewed ? 'beacon viewed' : 'beacon'}
				onClick={handleClick}
				aria-label="Open modal"
			>
				{icon ? icon : <InfoIcon />}
			</button>

			<div className={modalActive ? 'modal active' : 'modal'}>
				<button
					className="modal__mask"
					onClick={() => setModalActive(false)}
					aria-label="Close modal"
				></button>

				<div className="modal__content">
					<button
						className="modal__close"
						onClick={() => setModalActive(false)}
						aria-label="Close modal"
					>
						<CloseIcon />
					</button>
					{children}
				</div>
			</div>
		</>
	) : null
}
